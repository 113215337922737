body {
  background-color: #fff;
  color: #000;
}

.milkdown .ProseMirror {
  padding: 0 0 0 60px;
}

.cm-editor {
  font-size: 16px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.htmx-request {
  pointer-events: none;
  background: #eee;
}

.trix-content ul {
  list-style: disc;
}

.trix-content ol {
  list-style: decimal;
}

.trix-content a {
  text-decoration: underline;
}

.trix-content strong {
  font-weight: 550;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

[x-cloak] {
  display: none !important;
}

trix-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
}

.map-marker {
  border: 3px solid blue;
  border-radius: 8px;
  background: RGBa(0, 0, 255, 0.1);
  width: 14px;
  height: 14px;
}

@media (prefers-color-scheme: dark) {
  trix-toolbar .trix-button {
    filter: invert();
  }

  trix-toolbar {
    background: var(--color-slate-800);
  }

  trix-editor {
    color: white;
  }

  trix-toolbar .trix-button:disabled {
    filter: invert() grayscale(1) brightness(2);
  }
  trix-toolbar .trix-button--icon::before {
    opacity: 1;
  }
  trix-toolbar .trix-button--icon:disabled::before {
    opacity: 0.5;
  }
}
